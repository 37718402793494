/*    Colors    */
// Primary
$primary: #dbbb2d;
$primary-faded-1: rgba(219, 187, 45, 0.1);
$primary-faded-2: rgba(219, 187, 45, 0.2);
$primary-faded-3: rgba(219, 187, 45, 0.3);
$primary-faded-4: rgba(219, 187, 45, 0.4);
$primary-faded-5: rgba(219, 187, 45, 0.5);
$primary-faded-6: rgba(219, 187, 45, 0.6);
$primary-faded-7: rgba(219, 187, 45, 0.7);
$primary-faded-8: rgba(219, 187, 45, 0.8);
$primary-faded-9: rgba(219, 187, 45, 0.9);

// Secondary
$secondary: #646464;
$secondary-faded-1: rgba(100, 100, 100, 0.1);
$secondary-faded-2: rgba(100, 100, 100, 0.2);
$secondary-faded-3: rgba(100, 100, 100, 0.3);
$secondary-faded-4: rgba(100, 100, 100, 0.4);
$secondary-faded-5: rgba(100, 100, 100, 0.5);
$secondary-faded-6: rgba(100, 100, 100, 0.6);
$secondary-faded-7: rgba(100, 100, 100, 0.7);
$secondary-faded-8: rgba(100, 100, 100, 0.8);
$secondary-faded-9: rgba(100, 100, 100, 0.9);

// Success
$success: #8abd37;
$success-faded-1: rgba(21, 87, 36, 0.1);
$success-faded-2: rgba(21, 87, 36, 0.2);
$success-faded-3: rgba(21, 87, 36, 0.3);
$success-faded-4: rgba(21, 87, 36, 0.4);
$success-faded-5: rgba(21, 87, 36, 0.5);
$success-faded-6: rgba(21, 87, 36, 0.6);
$success-faded-7: rgba(21, 87, 36, 0.7);
$success-faded-8: rgba(21, 87, 36, 0.8);
$success-faded-9: rgba(21, 87, 36, 0.9);

// Info
$info: #5387a3;
$info-faded-1: rgba(83, 135, 163, 0.1);
$info-faded-2: rgba(83, 135, 163, 0.2);
$info-faded-3: rgba(83, 135, 163, 0.3);
$info-faded-4: rgba(83, 135, 163, 0.4);
$info-faded-5: rgba(83, 135, 163, 0.5);
$info-faded-6: rgba(83, 135, 163, 0.6);
$info-faded-7: rgba(83, 135, 163, 0.7);
$info-faded-8: rgba(83, 135, 163, 0.8);
$info-faded-9: rgba(83, 135, 163, 0.9);

// Warning
$warning: #dbbb2d;
$warning-faded-1: rgba(133, 100, 4, 0.1);
$warning-faded-2: rgba(133, 100, 4, 0.2);
$warning-faded-3: rgba(133, 100, 4, 0.3);
$warning-faded-4: rgba(133, 100, 4, 0.4);
$warning-faded-5: rgba(133, 100, 4, 0.5);
$warning-faded-6: rgba(133, 100, 4, 0.6);
$warning-faded-7: rgba(133, 100, 4, 0.7);
$warning-faded-8: rgba(133, 100, 4, 0.8);
$warning-faded-9: rgba(133, 100, 4, 0.9);

// Danger
$danger: #cf624f;
$danger-faded-1: rgba(114, 28, 36, 0.1);
$danger-faded-2: rgba(114, 28, 36, 0.2);
$danger-faded-3: rgba(114, 28, 36, 0.3);
$danger-faded-4: rgba(114, 28, 36, 0.4);
$danger-faded-5: rgba(114, 28, 36, 0.5);
$danger-faded-6: rgba(114, 28, 36, 0.6);
$danger-faded-7: rgba(114, 28, 36, 0.7);
$danger-faded-8: rgba(114, 28, 36, 0.8);
$danger-faded-9: rgba(114, 28, 36, 0.9);

// Dark
$dark: #1b1e21;
$dark-faded-1: rgba(27, 30, 33, 0.1);
$dark-faded-2: rgba(27, 30, 33, 0.2);
$dark-faded-3: rgba(27, 30, 33, 0.3);
$dark-faded-4: rgba(27, 30, 33, 0.4);
$dark-faded-5: rgba(27, 30, 33, 0.5);
$dark-faded-6: rgba(27, 30, 33, 0.6);
$dark-faded-7: rgba(27, 30, 33, 0.7);
$dark-faded-8: rgba(27, 30, 33, 0.8);
$dark-faded-9: rgba(27, 30, 33, 0.9);

// Light
$light: #818182;
$light-faded-1: rgba(129, 129, 130, 0.1);
$light-faded-2: rgba(129, 129, 130, 0.2);
$light-faded-3: rgba(129, 129, 130, 0.3);
$light-faded-4: rgba(129, 129, 130, 0.4);
$light-faded-5: rgba(129, 129, 130, 0.5);
$light-faded-6: rgba(129, 129, 130, 0.6);
$light-faded-7: rgba(129, 129, 130, 0.7);
$light-faded-8: rgba(129, 129, 130, 0.8);
$light-faded-9: rgba(129, 129, 130, 0.9);

// Muted
$muted: #6C757D;
$muted-faded-1: rgba(108, 117, 125, 0.1);
$muted-faded-2: rgba(108, 117, 125, 0.2);
$muted-faded-3: rgba(108, 117, 125, 0.3);
$muted-faded-4: rgba(108, 117, 125, 0.4);
$muted-faded-5: rgba(108, 117, 125, 0.5);
$muted-faded-6: rgba(108, 117, 125, 0.6);
$muted-faded-7: rgba(108, 117, 125, 0.7);
$muted-faded-8: rgba(108, 117, 125, 0.8);
$muted-faded-9: rgba(108, 117, 125, 0.9);

$white: #FFFFFF;
$off-white: #F5F6F6;
$black: #000000;

// Flat-ui colors
/* Default Palette */
$turquoise: #1abc9c;
$green-sea: #16a085;
$sun-flower: #f1c40f;
$orange: #f39c12;
$emerald: #2ecc71;
$nephritis: #27ae60;
$carrot: #e67e22;
$pumpkin: #d35400;
$peter-river: #3498db;
$belize-hole: #2980b9;
$alizarin: #e74c3c;
$pomegranate: #c0392b;
$amethyst: #9b59b6;
$wisteria: #8e44ad;
$clouds: #ecf0f1;
$silver: #bdc3c7;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;
$concrete: #95a5a6;
$asbestos: #7f8c8d;

$soothing-breeze: #636e72;

$border-line: #E6E6E6;

$auxcon-blue: #5e97d8;
$auxcon-dark: #4a4a4a;
$auxcon-gold: #dbbb2d;
$auxcon-green: #8abd37;
$auxcon-grey: #CCCCCC;
$auxcon-red: #cf624f;

$toast-grey: #666666;
$snackbar-grey: #202124;
$light-grey: #F3F3F3;
$disabled-event-grey: #AAA69D;

/*  Utilities   */
$layer-0: 0;
$layer-1: 1;
$layer-2: 10;
$layer-3: 100;
$layer-4: 1000;
$layer-5: 10000;
$layer-6: 100000;
$layer-7: 1000000;
$layer-8: 10000000;
$layer-9: 100000000;
$layer-10: 10000000000;

/* Textual */
$font-family: Avenir, sans-serif;
$font-size: 12px;
$font-size-tab: 14px;

$padding-page: 26px;
$padding-input: 5px 10px;
$padding-menu-item: 8px 20px;

$btn-border-radius: 4px;
$btn-sm-border-radius: 2px;
$btn-md-border-radius: 4px;
$btn-lg-border-radius: 8px;