// src/sass/styles.scss
@import url("../../node_modules/jquery-ui/themes/base/all.css");
// @import url("../../node_modules/devextreme/dist/css/dx.common.css");
// @import url("../../node_modules/devextreme/dist/css/dx.light.css");
@import './variables';
@import './mixins';
// @import './tables';
@import './loader';

// The dropdown wrapper (`<div>`)
.dropup,
.dropdown {
  position: relative;

  .dropdown-item {
    cursor: pointer;
  }
}

.tblChart {
  tbody {
    height: auto !important;
  }
}

table {
  tbody {
    height: auto !important;
  }
}

.bs-datepicker {
  right: 60px !important;
}

// .dropdown-toggle {
//   // Generate the caret automatically
// //   @include caret;
// }
.fal {
  margin-right: 5px !important;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0; // override default ul
  font-size: 1rem; // Redeclare because nesting can cause inheritance issues
  color: #212529;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  ;
  //   @include border-radius($dropdown-border-radius);
  //   @include box-shadow($dropdown-box-shadow);
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    margin-top: 0;
    // margin-bottom: $dropdown-spacer;
  }

  // .dropdown-toggle {
  //   // @include caret(up);
  // }
}

.dropright {
  .dropdown-menu {
    margin-top: 0;
    // margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {

    // @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    margin-top: 0;
    // margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {

    // @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// Dividers (basically an `<hr>`) within the dropdown
// .dropdown-divider {
// //   @include nav-divider($dropdown-divider-bg);
// }

// Links, buttons, and more within the dropdown menu

// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: .25rem 1.5rem;
  clear: both;
  //   font-weight: $font-weight-normal;
  color: #212529;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s

  //   @include hover-focus {
  //     color: #dbbb2d;
  //     text-decoration: none;
  //     // @include gradient-bg($dropdown-link-hover-bg);
  //   }

  &.active,
  &:active {
    background-color: #dbbb2d !important;
    text-decoration: none;
    // @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: transparent;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    // @if $enable-gradients {
    //   background-image: none;
    // }
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  //   padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0; // for use with heading elements
  //   font-size: $font-size-sm;
  //   color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}


* {
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

* {
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}

// make the chart legend white so that it won't be visible to users
text.jqx-chart-legend-text {
  fill: $white;
}

line {
  stroke: $light-grey;
}

.ng-valid[required],
.ng-valid.required {
  border: 1px solid $border-line;
  /* Back to normal */
}

.ng-invalid:not(form) {
  border: 1px solid $danger;
  /* Red */
}

.jqx-knob-label,
.jqx-chart-axis-text,
.jqx-chart-label-text,
.jqx-chart-tooltip-text,
.jqx-chart-legend-text {
  fill: #333333;
  color: #333333;
  font-size: 12px;
  font-family: Inter, sans-serif;
  // font-weight: bold;
}

html {
  box-sizing: border-box;
  cursor: default;
  line-height: 1.5 !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: $secondary;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 12px !important;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 14px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(12, 11, 11, 0.3);
  box-shadow: inset 0 0 2px rgba(12, 11, 11, 0.3);
}

app-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;

  &.material {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  }

  // &.paper{}
}

// end app-navbar{}

router-outlet+* {
  position: relative;
  top: 100px;
}

// end router-outlet{}

.row {
  display: flex;
  justify-content: space-evenly;
  padding: 8px 26px;

  @media only screen and (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.well {
  background-color: $off-white;
  padding: 10px;
  border: 1px solid $border-line;
  display: block;

  @media only screen and (max-width: 1200px) {
    padding: 5px;
  }

  &.well-fluid {
    width: 100%;
  }
}

.container {
  @media only screen and (max-width: 768px) {
    width: auto;
  }

  @media only screen and (min-width: 768px) {
    width: 750px;
  }

  @media only screen and (min-width: 992px) {
    width: 970px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1170px;
  }
}

// end .container{}

.container-fluid {
  // padding: 10px 26px;
  width: 100%;
  margin-top: 37px;

  @media only screen and (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// end .container-fluid{}

.container-fluid-subtitle {
  padding-left: 26px;
  padding-right: 26px;
}

// end container-fluid-subtitle {}

/* Jumbotron */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/* Navigations */
nav.navbar {
  height: 60px;
  display: flex;
  justify-content: center;
  box-sizing: border-box !important;
  position: static !important;

  @media only screen and (max-width: 1200px) {
    height: 68px;
  }
}

// end nav.navbar{}

.navbar-brand {
  width: 170px;
  height: 70px;
  flex-grow: 1;
}

.navbar-brand img {
  width: 170px;
}

.navbar-nav {
  flex-grow: 6;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  flex-direction: unset !important;
}

.navbar-nav .nav-item {
  display: inline-block;
  margin-left: 30px;
}

.navbar-nav .nav-item .btn {
  width: 150px;
}

nav {
  &.navbar-tabs {
    display: flex;
    justify-content: flex-start;

    .tab {
      height: 38px;
      min-width: 222px;
      cursor: pointer;
      text-align: center;
      font-size: $font-size-tab;
      line-height: 3;
      padding: 1px 20px;
      border: 1px solid transparent;
      border-bottom: 1px solid $off-white;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }

      &.selected {
        background-color: $off-white;
        border: 1px solid $border-line;
        border-bottom: 1px solid $off-white;
      }
    }

    // end .tab{}

  }

  // end .navbar-tabs{}

}

// end nav{}

.menu-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  a {
    display: block;
    text-transform: initial;
    color: $secondary;

    i~ ::first-letter {
      text-transform: uppercase;
    }

    &:active,
    &:visited,
    &:focus {
      color: $secondary;
    }
  }

  // end a{}

  .menu-item {
    &:hover {
      background-color: $off-white;
    }

    &:not(.divider) {
      padding: 8px 20px;
    }

    // end :not(.divider)

    &.divider hr {
      margin-top: 0;
      margin-bottom: 0;
    }

    // end &.divider hr{}

  }

  // .menu-item{}

}

// end .menu-list{}

/* Forms */

.form {

  .form-group,
  .table-row span {
    margin-bottom: 0 !important;
    position: relative;

    .datalist {
      display: none;
      position: absolute;
      background-color: $white;
      width: 100%;
      max-height: 408px;
      overflow-y: scroll;
      z-index: $layer-2;
      top: 51px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);

      &.show {
        display: block;
      }

      // end &.show{}

      .datalist-item {
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
          background-color: $off-white;
        }

        // end &:hover{}

        &.selected {
          background-color: $info;
        }

        // end &.selected{}

        .list-item-icon {
          margin-right: 5px;
        }

        // end .list-item-icon{}
      }

      // end .datalist-item{}
    }

    // end .datalist{}

    label {
      padding-left: 10px;
      font-size: 14px;
    }

    // end label{}

    label span {
      color: $danger !important;
    }

    // end label span{}

    .input-arrow {
      position: absolute;
      right: 10px;
      top: 30px;
    }

    // end .input-arrow{}
  }

  // end .form-group, .table-row span{}

  .table-row span {
    .input-arrow {
      top: 10px;
    }

    // end .input-arrow{}

    .datalist {
      top: 31px;
    }

    // end .datalist{}
  }

  // end .table-row span{}

  .row {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .form-group {
      flex: 1;
      margin-right: 8px;
      position: relative;

      .datalist {
        position: absolute;
        background-color: $white;
        width: 100%;
        z-index: $layer-2;
        top: 51px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
          0 1px 2px rgba(0, 0, 0, 0.24);
      }

      label {
        padding-left: 10px;
        font-size: 14px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  // end .row{}

  /* Input */
  input {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    background: $white;
    font-size: $font-size;
    font-weight: 300;
    line-height: 2.58;
    text-align: left;
    color: $auxcon-dark !important;
    border: 1px solid $border-line;
    border-radius: 2px;
    outline: 0;
    padding: 5px 10px;
    margin-bottom: 10px;

    &:focus {
      outline: 1px solid $primary;
    }

    // end &:focus{}

    &.has-success {
      border: 1px solid $success;
    }

    // end &.has-success{}

    &.has-error {
      border: 1px solid $danger;
    }

    // end &.has-error{}
  }

  // end input{}

  /* Select */
  select {
    position: relative;
    width: 100%;
    height: 30px;
    background: $white;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    text-align: left;
    color: $auxcon-dark;
    border: 1px solid $border-line;
    border-radius: 2px;
    outline: 0;
    padding: 5px 10px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../assets/images/icons/font-awesome/fa-angle-down.png');
    background-position: calc(100% - 9px) calc(1em + 0px),
      calc(100% - 5px) calc(1em + 0px),
      calc(100% - 2.5em) 0.5em;
    background-size: 9px 8px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:focus {
      outline: 1px solid $primary;
    }

    // end &:focus{}
  }

  // end select{}

  /* Labels */
  .label {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  // end .label{}
}


.table {
  color: #646464 !important;
}

// end .form{}

/* Buttons */
.btn {
  font-size: 14px !important;
  font-weight: bold !important;
  height: 40px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 1px 20px !important;
  border: none !important;
  border-radius: 2px;
  cursor: pointer;

  &.disabled {
    background: $auxcon-grey !important;
    color: $white;
    cursor: not-allowed;

    &:hover,
    &:active {
      background-color: $auxcon-grey !important;
      color: $white;
    }
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primary-faded-8;
    }

    &:active {
      background-color: $primary-faded-6;
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: $secondary-faded-8;
    }

    &:active {
      background-color: $secondary-faded-6;
    }
  }

  &.btn-success {
    background-color: $success;
    color: $white;

    &:hover {
      background-color: $success-faded-8;
    }

    &:active {
      background-color: $success-faded-6;
    }
  }

  &.btn-danger {
    background-color: $auxcon-red;
    color: $white;

    &:hover {
      background-color: $pomegranate;
    }

    &:active {
      background-color: $danger;
    }
  }

  &.btn-info {
    background-color: $info;
    color: $white;

    &:hover {
      background-color: $info-faded-8;
    }

    &:active {
      background-color: $auxcon-blue;
    }
  }

  // end &.btn-info{}

  &.btn-text {
    background: none;
    outline: none;
    color: $primary;
    margin: 0;
    border: 0;
  }

  &.btn-outlined {
    color: $primary;
    border: 1px solid $primary-faded-3;
  }

  &.btn-contained {
    min-width: 64px;

    .btn-icon {
      margin-right: 8px;
    }
  }

  // end &.btn-contained{}

  &.btn-toggle {
    padding: 10px;
    margin-right: 1px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  // end &.btn-toggle{}
}

// end .btn{}

.btn-group {
  position: relative;
  display: flex;
  height: 26px;
  // border-bottom-left-radius: 44px;
  // border-top-left-radius: 44px;

  span.clickable,
  a {
    padding: 10px 0px;
    border-radius: 2px 0 0 2px;
    position: relative;
    color: #fff !important;
    background-color: #dbbb2d;
  }

  // end .clickable{}

  .fa {
    color: #fff;
    background-color: #dbbb2d;
    // border-bottom-right-radius: 29px !important;
    // border-top-right-radius: 29px !important;
    border: 0px solid $border-line;
    border-left-color: transparent;
    border-radius: 0 2px 2px 0;
    padding: 16px 10px;
  }

  // end .fa{}
}

// end .btn-group{}

/* Input */
input {

  :not(input[type='checkbox']),
  :not(input[type='radio']) {
    -webkit-appearance: none;
    width: 100%;
    height: 30px;
    background: $white;
    font-size: 12px;
    font-weight: 300;
    line-height: 2.58;
    text-align: left;
    color: $auxcon-dark;
    border: 1px solid $border-line;
    border-radius: 2px;
    outline: 0;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
}

/* Select */
select {
  width: 100%;
  height: 30px;
  background: $white;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  text-align: left;
  color: $auxcon-dark;
  border: 1px solid $border-line;
  border-radius: 2px;
  outline: 0;
  padding: 5px 10px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../assets/images/icons/font-awesome/fa-angle-down.png');
  background-position: calc(100% - 9px) calc(1em + 0px),
    calc(100% - 5px) calc(1em + 0px),
    calc(100% - 2.5em) 0.5em;
  background-size: 9px 8px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

/* Labels */
.label {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

/* Search */
.column-search {
  position: relative;

  [type=search] {
    padding-left: 32px !important;
  }

  // end [type=search]
}

// end .column-search{}

/* Modal dialogs  */
.modal {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $dark-faded-6;
  z-index: $layer-9 !important;
  display: flex !important;
  justify-content: center;

  &.show {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.fade {
    .modal-dialog {
      transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;
      transition: transform .3s ease-out, -webkit-transform .3s ease-out;
      -webkit-transform: translate(0, -25%);
      transform: translate(0, -25%);
    }
  }

  .modal-content {
    z-index: $layer-10;
    width: 690px;
    min-height: 200px;
    background-color: $white;
    margin: 14.75rem auto auto !important;
    padding: 8px 20px 20px;
    border-radius: 4px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.19),
      0 12px 12px rgba(0, 0, 0, 0.23);

    // @media only screen and (max-width: 1024px) {
    //   margin: 5.75rem auto auto;
    // }

    @media only screen and (max-height: 670px) {
      margin: 9.75rem auto auto !important;
    }

    @media only screen and (max-height: 650px) {
      margin: 8.75rem auto auto !important;
    }

    @media only screen and (max-height: 620px) {
      margin: 5.75rem auto auto !important;
    }

    @media only screen and (max-height: 545px) {
      margin: 3.75rem auto auto !important;
    }

    .row {
      padding-top: 0;

      &:first-of-type {
        padding-top: 15px;
      }
    }

    .modal-dialog {
      position: relative;
      width: auto;
      max-width: 100% !important;
      margin: .5rem !important;
      pointer-events: visible !important;

      .modal-header {
        position: relative;
        border-bottom: 0px;
        padding: 0;

        h2 {
          display: block !important;
          font-size: 1.5em !important;
          margin-block-start: 0.83em !important;
          margin-block-end: 0.83em !important;
          margin-inline-start: 0px !important;
          margin-inline-end: 0px !important;
          font-weight: bold !important;
          letter-spacing: 0px !important;
        }

        .modal-dismiss {
          position: absolute !important;
          top: 0 !important;
          right: 0 !important;
          font-size: 1.5rem !important;
          font-weight: 700 !important;
          line-height: 1 !important;
          background: none;
          color: $secondary-faded-6 !important;
          margin: -1rem -1rem -1rem auto !important;
          padding: 1rem !important;
          border: none !important;
          cursor: pointer !important;

          &:hover,
          &:active {
            color: $secondary;
          }

          &:active,
          &:focus {
            outline: none;
          }
        }

        // end .modal-dismiss{}
      }

      // end .modal-header{}

      .modal-body {
        min-height: 256px;

        .text-warning {
          color: #856404 !important;
        }
      }

      // end .modal-content{}

      .modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        border-top: 0px
      }

      // end .modal-footer{}

    }

    // .modal-dialog{}

  }

  // end .modal-content{}

}

// .modal{}

// Small devices (landscape phones, 576px and up)
@media only screen and (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }

  .hide-xs {
    display: none;
  }
}

// end @media only screen and (min-width: 576px)

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) {
  .hide-sm {
    display: none;
  }
}

// end @media only screen and (min-width: 768px)

// Large devices (desktops, 992px and up)
@media only screen and (min-width: 992px) {
  .hide-md {
    display: none;
  }
}

// end @media only screen and (min-width: 992px)

// Extra large devices (large desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
  .hide-lg {
    display: none;
  }
}

// end @media only screen and (min-width: 1200px)

/* Utilities */
.page-title {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  background-color: #f5f6f6;
  border: solid 1px #f0f0f0;
  text-transform: uppercase;
  padding: 9px 0 9px 42px !important;
  margin-bottom: 10px;
}

// end .page-title{}

.page-subtitle {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  background-color: #f5f6f6;
  border: solid 1px #f0f0f0;
  text-transform: uppercase;
  padding: 9px 0 9px 20px !important;
  margin-bottom: 10px;
}

// end .page-title{}

.clickable {
  cursor: pointer;
}

// end .clickable{}

.highlight-hover {
  &:hover {
    color: $primary !important;
  }
}

// end .highlight-primary{}

.text-muted {
  color: $muted !important;
}

// end .text-muted{}

.text-primary {
  color: $primary !important;
}

// end .text-primary{}

.text-success {
  color: $success !important;
}

// end .text-success{}

.text-info {
  color: $info !important;
}

// end .text-info{}

.text-warning {
  color: $warning !important;
}

// end .text-warning{}

.text-danger {
  color: $auxcon-red !important;
}

// end .text-danger{}

.text-secondary {
  color: $secondary !important;
}

// end .text-secondary{}

.text-white {
  color: $white !important;
}

// end .text-white{}

.text-dark {
  color: $dark !important;
}

// end .text-dark{}

.text-body {
  color: $secondary !important;
}

// end .text-body{}

.text-light {
  color: $light !important;
}

// end .text-light{}

.hidden-xs,
.hidden-sm {
  display: inherit;
}

// end .hidden-xs, .hidden-sm{}

.visible-xs,
.visible-sm {
  display: none;
}

// end .visibile-xs, .visibile-sm{}


@media only screen and (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }

  // end .hidden-xs{}

  .visible-xs {
    display: block !important;
  }

  // end .visible-xs{}
}

// end @media only screen and (max-width: 768px)

@media only screen and (max-width: 1024px) {
  .hidden-sm {
    display: none !important;
  }

  // end .hidden-sm{}

  .visible-sm {
    display: block !important;
  }

  // end .visible-sm{}
}

// end @media only screen and (max-width: 768px)

/* Transitions and Animations */

.fade {
  transition: opacity .15s linear;
}

// end .fade{}

.hide {
  opacity: 0 !important;
  display: none !important;
}

// end .hide{}

.badge {
  position: relative;
  top: -2px;
  font-size: 14px;
  line-height: 1.75;
  color: $white;
  padding: 2px 4px;
  border-radius: 2px;

  &.badge-primary {
    background-color: $primary;
  }

  &.badge-secondary {
    background-color: $secondary;
  }

  &.badge-success {
    background-color: $success;
  }

  &.badge-danger {
    background-color: $danger;
  }

  &.badge-warning {
    background-color: $warning;
  }

  &.badge-info {
    background-color: $info;
  }

  &.badge-light {
    background-color: $light;
  }

  &.badge-dark {
    background-color: $success;
  }

  &.badge-card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

// end .badge{}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $auxcon-dark;
  color: $white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 11px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $auxcon-dark transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip.active .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Calendar */
.fc-toolbar {
  &.fc-header-toolbar {
    * {
      text-transform: capitalize !important;
    }


    // end *{}

    .fc-button {
      height: 30px !important;
      background-color: $white !important;
      color: $secondary !important;
      display: inline-block !important;
      text-align: center !important;
      vertical-align: middle !important;
      background: none !important;
      background-image: none !important;
      line-height: normal !important;
      white-space: nowrap !important;
      margin: 0 !important;
      padding: 0 40px !important;
      border: 1px solid $border-line !important;
      border-right: none !important;
      border-radius: 0 !important;
      z-index: 2 !important;
      outline: none !important;

      // &:hover{
      //   background-color: #f5f6f6 !important;
      // }

      &:focus {
        outline: none !important;
        background-color: $primary !important;
      }

      // end &:focus{}

      &:active {
        background-color: $primary-faded-9 !important;
        border: 1px solid #e6e6e6 !important;
      }

      // end &:active{}

      &:first-of-type {
        border-radius: 14px 0 0 14px !important;

      }

      // end &:first-of-type{}

      &:last-of-type {
        border-right: 1px solid $border-line !important;
        border-radius: 0 14px 14px 0 !important;
      }

      // end &:first-of-type{}

      &.fc-state-active,
      &.fc-state-hover {
        box-shadow: none !important;
        text-shadow: none !important;
        color: $white !important;
      }

      // &.fc-state-active{}

      &.fc-state-active {
        background-color: $primary !important;
      }

      // end &.fc-state-active{}

      &.fc-state-hover {
        color: $secondary !important;
        background-color: $off-white !important;
      }

      // end &.fc-state-active{}
    }


    // end button{}


    .fc-left {
      .fc-state-disabled {
        color: $white !important;
        background-color: $primary !important;
        text-shadow: none !important;
      }

      // end .fc-state-disabled{}
    }


    .fc-button-active {
      background-color: $primary !important;
      //background: $primary !important;
      color: #fff !important;
    }


    // end .fc-left{}
  }



  // end .fc-header-toolbar{}
}

// end .fc-toolbar{}
.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
  color: white;
  margin: 0;
  font-family: 'Inter', sans-serif !important;
}


.fc-event-outlook {
  background-color: #3887BE !important;
  border: 1px solid #3887BE !important;
  margin: 0px 3px;
}

.fc-event-outlook::after {
  content: "";
  background-image: url('../assets/images/icons/outlook_icon.png');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-color: #FFF;
  border: 1px solid #3887BE;
  background-position-x: center;
  background-position-y: center;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  position: absolute;
}


.fc-view-container {
  .fc-view {
    width: 100% !important;

    table {
      display: table !important;

      .fc-event {
        padding: 5px;
        box-sizing: border-box !important;
        min-height: 20px;
        cursor: pointer !important;
        color: white;

        &.fc-event-sent {
          cursor: default !important;
        }


      }

      // end .fc-event{}

      .fc-event-dot {
        text-align: left !important;
      }

      // end .fc-event-dot{}
    }

    // end table{}
  }

  // end .fc-view{}

  .fc-axis {
    &.fc-widget-content {
      &::first-letter {
        text-transform: capitalize !important;
      }

      // end &::first-letter{}
    }

    // end &.fc-widget-content
  }

  // end .fc-axis{}
}

// end .fc-view-container{}

td.fc-list-item-time.fc-widget-content {
  visibility: hidden;
}

// end td.fc-list-item-time.fc-widget-content{}
html,
body {
  height: 94%;
  color: #646464 !important;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
}

/* Date picker */
.bs-datepicker-head {
  background-color: $primary !important;
}

// end .bs-datepicker-head{}

.input-currency {
  text-align: right !important;
}

// end input-currency{}

/* DevExpress Document is loading - move to top */
.dxrd-preview.dxrd-designer-wrapper .dxrd-preview-wrapper .dxrd-preview-progress {
  top: 0 !important;
}

// end .dxrd-preview.dxrd-designer-wrapper .dxrd-preview-wrapper .dxrd-preview-progress{}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 2070567556 !important;
}

// end bs-datepicker-container{}

// Adjusted calendar colors for compatiblity by Shubhash
.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-day-sat:not(.fc-col-header-cell),
.fc-day-sun:not(.fc-col-header-cell) {
  background-color: #F3F3F3 !important;
}

.fc-prev-button .fc-button:focus {
  outline: none !important;
  background-color: #fff !important;
}

// .fc .fc-button-primary .first-of-type:focus {
//   outline: none !important;
//   background-color: #fff !important;
// }

.fc .fc-button-primary .first-of-type:active {
  background-color: #fff !important;
  border: 1px solid #e6e6e6 !important;
}


.fc-v-event {
  // background: #EBF8FB !important;
  border: none;
}

.fc-v-event-future {
  background: #dbbb2d !important;
}

// Adjusted calendar colors for compatiblity by Shubhash

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";